import { Injectable } from '@angular/core';
import { Service } from '../pages/my-booking/my-booking-page/sections/services/model/service';
import { GlobalConfigService } from './global-config.service';
import { ServiceConfigurationHelper } from './helpers/service.configuration.helper';

@Injectable()
export class ConfigurationService {
  private serviceConfigurationHelper: ServiceConfigurationHelper;

  constructor(private globalConfigService: GlobalConfigService) {
    this.serviceConfigurationHelper = new ServiceConfigurationHelper(
      globalConfigService
    );
  }

  services() {
    return this.serviceConfigurationHelper.serviceConfiguration();
  }

  get environmentLabel(): string {
    return this.globalConfigService.envLabel || '';
  }

  get customerServicePhoneNumber(): string {
    return this.globalConfigService.customerServicePhoneNumber || '';
  }
}
