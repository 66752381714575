import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {combineLatest, Subscription} from 'rxjs';
import {Booking} from '../../domain/booking/booking';
import {AgreementSignByType} from '../../domain/agreement/agreement-sign-by-type';
import {AgreementData} from '../../domain/agreement/agreement-data';
import {SignatureType} from '../../domain/agreement/signature-type';
import {distinctUntilChanged, finalize} from 'rxjs/operators';
import {AgreementService} from '../../domain/agreement/agreement-service';
import {BookingService} from '../../domain/booking/booking.service';
import {TranslateService} from '@ngx-translate/core';
import {EnvironmentService} from '../../core/services/environment.service';
import {InputMaskReturn, InputMaskService} from '../../core/services/input-mask.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AgreementFormStoreService} from '../../domain/agreement/agreement-form-store.service';
import {ReactiveFormHelper} from '../../core/services/reactive-form-helper';
import {MY_BOOKING_ROUTES} from '../../pages/my-booking/my-booking-routes';
import {AgreementHelper} from '../../domain/agreement/agreement-helper';

@Component({
  selector: 'agreement-sign',
  templateUrl: './agreements-sign.component.html'
})
export class AgreementsSignComponent implements OnInit, OnDestroy {
  subs = new Subscription();

  form = new UntypedFormGroup({});
  createInProgress = false;
  isNavigating = false;
  _booking!: Booking;
  signBy = [AgreementSignByType.INSURANCE_HOLDER, AgreementSignByType.SUBMITTER];

  fName = {
    insurance_holder_name: 'insurance_holder_name',
    insurance_holder_org_no: 'insurance_holder_org_no',
    insurance_reason: 'insurance_reason',
    insurance_holder_vat_subject: 'insurance_holder_vat_subject',
    signee_name: 'signee_name',
    signee_phone: 'signee_phone',
    signature_type: 'signature_type',
    sign_by_type: 'sign_by_type',
    email: 'email',
  };

  @Input()
  set booking(booking: Booking) {
    this._booking = booking;
  }

  get booking(): Booking {
    return this._booking;
  }

  constructor(private fb: UntypedFormBuilder,
              private router: Router, private route: ActivatedRoute,
              private agreementService: AgreementService,
              private bookingService: BookingService,
              private environmentService: EnvironmentService,
              private translateService: TranslateService,
              private agreementFormStoreService: AgreementFormStoreService,
              private inputMaskService: InputMaskService) {
  }

  ngOnInit(): void {
    this.createForm();
    this.updateFormIfAnyInStore();
    this.updateFormStore();
  }


  createForm(): void {
    const form = this.fb.group({
      [this.fName.insurance_holder_name]: this.booking.user_name,
      [this.fName.insurance_holder_org_no]: this.environmentService.signatureOrgno || this.booking.user_org_no,
      [this.fName.insurance_holder_vat_subject]: this.booking.insurance_vat_subject,
      [this.fName.insurance_reason]: null,
      [this.fName.signee_name]: [{value: this.booking.user_name, disabled: true}, Validators.required],
      [this.fName.signee_phone]: [this.booking.contact_phone, Validators.required],
      [this.fName.sign_by_type]: this.booking.canSignClaimAgreement
        ? AgreementSignByType.INSURANCE_HOLDER : AgreementSignByType.SUBMITTER,
      [this.fName.signature_type]: SignatureType.Click,
      [this.fName.email]: this.booking.contact_email
    });

    if (this.booking.canSignClaimAgreement) {
      form.get([this.fName.insurance_holder_name])?.setValidators(Validators.required);
      form.get([this.fName.insurance_holder_org_no])?.setValidators(Validators.required);
      form.get([this.fName.insurance_reason])?.setValidators(Validators.required);
    }

    this.updateSignee(form);
    this.form = form;
  }

  updateFormIfAnyInStore(): void {
    const data = this.agreementFormStoreService.get(this.booking.uuid);
    if (data && this.form) {
      this.form.patchValue(data);
    }
  }

  canSave(): boolean {
    return (!this.isProcessing()
      && this.form
      && this.form.valid);
  }

  public isProcessing(): boolean {
    return this.createInProgress || this.isNavigating;
  }

  updateFormStore(): void {
    this.subs.add(
      this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        const data = this.extractAgreementDataFromForm();
        this.agreementFormStoreService.set(this.booking.uuid, data);
      })
    );
  }

  create(): void {
    if (!this.canSave()) {
      ReactiveFormHelper.validateAllFormFields(this.form);
      return;
    }
    this.createInProgress = true;
    const data = this.extractAgreementDataFromForm();
    this.agreementService.create(this._booking.uuid, data).pipe(
      finalize(() => this.createInProgress = false)
    ).subscribe((res) => {
      if (res && res.url != null) {
        this.isNavigating = true;
        window.location.href = res.url;
      }
    });
  }

  private extractAgreementDataFromForm(): AgreementData {
    const data = {} as AgreementData;
    const formData = this.form.getRawValue();
    data.agreements = AgreementHelper.getAgreementTypesToSign(this.booking);

    data.signature_type = formData[this.fName.signature_type];
    data.signee_name = formData[this.fName.signee_name];
    data.signee_role = this.translateService.instant(formData[this.fName.sign_by_type]);
    data.signee_phone = formData[this.fName.signee_phone];
    data.digital_sign_text = this.translateService.instant('AGREEMENT_SIGN_DIGITAL');
    data.insurance_holder_name = formData[this.fName.insurance_holder_name];
    data.insurance_holder_org_no = formData[this.fName.insurance_holder_org_no];
    data.insurance_holder_vat_subject = formData[this.fName.insurance_holder_vat_subject];
    data.insurance_type_of_loss = this.translateService.instant(this.booking.insurance_type_of_loss);
    data.insurance_reason = formData[this.fName.insurance_reason];
    data.email = formData[this.fName.email];
    return data;
  }

  orgNoMask(): InputMaskReturn {
    return this.inputMaskService.orgNoMask(this.environmentService.countryCode);
  }

  phoneMask(): InputMaskReturn {
    return this.inputMaskService.phoneMask;
  }

  offerCvInsurance(): boolean {
    return this.booking.hasCourtesyVehicleCar() && !this.booking.extra_courtesy_vehicle_insurance;
  }

  updateSignee(form: UntypedFormGroup): void {
    const $signByType = ReactiveFormHelper.getObsWithInitial(form, this.fName.sign_by_type);
    const $insuranceHolderName = ReactiveFormHelper.getObsWithInitial(form, this.fName.insurance_holder_name);
    this.subs.add(
      combineLatest([$signByType, $insuranceHolderName]).subscribe(([signByType, insuranceHolderName]) => {
        const isInsuranceHolder: boolean = (signByType === AgreementSignByType.INSURANCE_HOLDER);
        ReactiveFormHelper.setValue(form, this.fName.signee_name, insuranceHolderName, isInsuranceHolder);
        ReactiveFormHelper.enableOrDisableCtrl(form, this.fName.signee_name, isInsuranceHolder);
      })
    );
  }

  next(): void {
    if (!this.canSave()) {
      ReactiveFormHelper.validateAllFormFields(this.form);
      return;
    }
    const key = this.booking ? this.booking.key : null;
    this.router.navigate(['bookings/' + key + '/' + MY_BOOKING_ROUTES.AGREEMENTS_COURTESY_VEHICLE_EXTRA_PAGE]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  isFieldInvalid(name: string): boolean {
    if (this.form == null) {
      return false;
    }
    const ctrl = this.form.get(name);
    return ctrl != null ? ctrl.invalid && ctrl.touched : false;
  }

  get orgnoHidden(): boolean {
    return !!this.environmentService.signatureOrgno;
  }
}
