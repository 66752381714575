import {Component, ElementRef, Host, Input, OnInit} from '@angular/core';
import {Booking} from '../../../../../domain/booking/booking';

@Component({
  selector: 'mybooking-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  public hostClasses: string;

  @Input()
  public booking: Booking | null = null;
  @Input()
  public ical: string | null = null;

  constructor(private el: ElementRef<HTMLElement>) {
    this.hostClasses = el.nativeElement.className;
  }

  ngOnInit(): void {
  }

}
